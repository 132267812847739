// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '../src/bootstrap_init.js'
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
import helpers from '../src/helpers';

Rails.start()
ActiveStorage.start()

document.addEventListener("turbo:before-cache", function() {
  $(".flatpickr-input").flatpickr('destroy');
});

document.addEventListener("turbo:frame-missing", (event) => {
  console.log(event)
  event.target.innerHTML = helpers.renderErrorTemplate(event.detail.response.statusText)
  event.preventDefault()
})

document.addEventListener("turbo:fetch-request-error", (event) => {
  console.log(event)
  event.target.innerHTML = helpers.renderErrorTemplate(event.detail.error.message)

  const reloadIcon = event.target.querySelector('.bi-arrow-repeat')
  reloadIcon.addEventListener('click', (click_event)=> {
    event.target.reload()
    click_event.preventDefault()
  })
  event.preventDefault()
})
