import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["text", "icon"]

    static values = {
        text: String
    }

    connect() {
    }

    replace(_) {
        const originalText = this.textTarget.innerText
        this.textTarget.innerText = this.textValue
        this.element.classList.add('btn-primary')
        this.element.classList.remove('btn-outline-primary')
        this.iconTarget.classList.remove('d-none')
        this.timer = setTimeout(() => {
            this.textTarget.innerText = originalText
            this.element.classList.remove('btn-primary')
            this.element.classList.add('btn-outline-primary')
            this.iconTarget.classList.add('d-none')
        }, 700)
    }
}
